.App {
  text-align: center;
}

.App-iframe {
  border: none;
}

.header-image, .footer-image {
  width: 100%;
}
